import { toMilliseconds } from './time'

export interface Params {
	message: string
	path: string
	timestamp: number
}

export const pattern = /^\/(?:edit\/)?(\d{8,})\/?(.*)/

export const parse = (path: string = location.pathname) => {
	const match = pattern.exec(path)

	if (match) {
		return {
			message: decodeURIComponent(match[2]),
			path,
			timestamp: toMilliseconds(Number(match[1])),
		} as Params
	}
}

export const encode = (...segments: string[]) =>
	`/${segments.map((s) => encodeURIComponent(s)).join('/')}`
