export const get = (key: string) => {
	const raw = localStorage.getItem(key)
	if (raw) {
		return JSON.parse(raw)
	}
}

export const set = (key: string, value: any) => {
	const data = JSON.stringify(value)
	localStorage.setItem(key, data)
}
