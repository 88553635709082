import { h } from 'preact'
import { pad, pluralize } from '../../../../services/format'
import styles from './ClockNumber.less'

export const ClockNumber = (props: { number: number; label: string }) => {
	const digits = pad(props.number, 2, '0')
	const label = pluralize(props.number, props.label)

	return (
		<div className={styles.clockNumber}>
			<div className={styles.digit}>{digits}</div>
			<div className={styles.title}>{label}</div>
		</div>
	)
}
