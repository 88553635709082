import { Params, parse } from './params'
import * as storage from './storage'

export const get = () => (storage.get('history') || []) as Params[]

export const set = (entries: Params[]) => storage.set('history', entries)

export const add = (path: string) => {
	const params = parse(path)
	if (params) {
		const history = get()
		if (!history.some((entry) => entry.path === params.path)) {
			set([...history, params])
		}
	}
}

export const remove = (path: string) => {
	const filtered = get().filter((entry) => entry.path !== path)
	set(filtered)

	return filtered
}
