import { h } from 'preact'
import { toMilliseconds } from '../../services/time'
import { Clock } from '../Common/Clock/Clock'
import { FullscreenButton } from '../Common/FullscreenButton/FullscreenButton'
import { Page } from '../Common/Page/Page'
import { Controls } from '../Controls/Controls'
import styles from './CountdownPage.less'

export type CountdownPageProps = {
	message?: string
	timestamp?: number
	path?: string
	toggleShowHistory: (show?: boolean) => void
}

export const CountdownPage = (props: CountdownPageProps) => {
	const timestamp = toMilliseconds(Number(props.timestamp))

	return (
		<Page className={styles.countdown}>
			<h1 className={styles.message}>{props.message}</h1>
			<Clock timestamp={timestamp} />
			<FullscreenButton />
			<Controls
				message={props.message || ''}
				timestamp={timestamp}
				toggleShowHistory={props.toggleShowHistory}
			/>
		</Page>
	)
}
