import { h } from 'preact'
import { useState } from 'preact/hooks'
import { route } from '../../services/router'
import { formatLocalIso, toMilliseconds, toMinutes } from '../../services/time'
import { FormGroup } from '../Common/FormGroup/FormGroup'
import { Page } from '../Common/Page/Page'
import styles from './ConfigPage.less'

const getNow = () => {
	const now = new Date()
	now.setMonth(now.getMonth() + 1)
	now.setMinutes(0)

	return now.getTime()
}

type ConfigPageProps = {
	message?: string
	timestamp?: number
} & { path: string }

export function ConfigPage(props: ConfigPageProps) {
	const timestamp = toMilliseconds(Number(props.timestamp)) || getNow()

	let dateString: string
	try {
		dateString = formatLocalIso(timestamp)
	} catch (err) {
		dateString = formatLocalIso(Date.now())
	}

	const [message, setMessage] = useState(props.message || '')
	const [date, setDate] = useState(dateString.substring(0, 10))
	const [time, setTime] = useState(dateString.substring(11, 16))

	const handleSubmit = (event: Event) => {
		event.preventDefault()
		const messageEncoded = encodeURIComponent(message.trim())
		const timestamp = new Date(`${date}T${time}`).getTime()
		route(`/${toMinutes(timestamp)}/${messageEncoded}`)
	}

	return (
		<Page className={styles.configPage}>
			<h1>
				A <em>Countdown</em> Clock
			</h1>
			<p className={styles.tagline}>
				A free countdown clock for your next marvelous milestone
			</p>
			<form className="config-form" onSubmit={handleSubmit}>
				<FormGroup>
					Message
					<input
						className="message-input"
						maxLength={30}
						name="message"
						onChange={(event: any) => setMessage(event.target.value)}
						placeholder="Event name"
						value={message}
					/>
				</FormGroup>
				<FormGroup className={styles.date}>
					Date
					<input
						className="date-input"
						name="date"
						onChange={(event: any) => setDate(event.target.value)}
						type="date"
						required
						value={date}
					/>
				</FormGroup>
				<FormGroup className={styles.time}>
					Time
					<input
						className="time-input"
						name="time"
						onChange={(event: any) => setTime(event.target.value)}
						type="time"
						value={time}
					/>
				</FormGroup>
				<button className="btn btn-large" type="submit">
					Create countdown
				</button>
			</form>
		</Page>
	)
}
