export const init = () => {
	if ('serviceWorker' in navigator) {
		window.addEventListener('load', registerWorker)
	}
}

const registerWorker = () => {
	let newWorker: ServiceWorker | null
	navigator.serviceWorker
		.register('/sw.js')
		.then((registration) => {
			registration.addEventListener('updatefound', () => {
				newWorker = registration.installing
				newWorker?.addEventListener('statechange', () => {
					if (
						newWorker?.state === 'installed' &&
						navigator.serviceWorker.controller
					) {
						window.location.reload()
					}
				})
			})
		})
		.catch((registrationError) => {
			console.error('SW registration failed: ', registrationError)
		})
}
