declare global {
	interface Window {
		gtag?: (...args: any[]) => void
	}
}

const gtag = (...args: any[]) => window.gtag?.(...args)

export const sendEvent = (category: string, action: string, label?: string) => {
	if (label) {
		gtag('send', 'event', category, action, label)
	} else {
		gtag('send', 'event', category, action)
	}
}

/**
 * Tracks a JavaScript error with optional fields object overrides.
 * This function is exported so it can be used in other parts of the codebase.
 * E.g.:
 *
 *    `fetch('/api.json').catch(trackError);`
 */
export const trackError = (err?: Error, fieldsObj = {}) => {
	gtag('send', 'event', {
		eventCategory: 'Error',
		eventAction: err?.name || '(no error name)',
		eventLabel: `${err?.message}\n${err?.stack || '(no stack trace)'}`,
		nonInteraction: true,
		...fieldsObj,
	})
}

/**
 * Tracks any errors that may have occured on the page prior to analytics being
 * initialized, then adds an event handler to track future errors.
 */
const trackErrors = () => {
	// Errors that have occurred prior to this script running are stored on
	// `window.__e.q`, as specified in `index.html`.
	const loadErrorEvents = ((window as any).__e && (window as any).__e.q) || []

	const trackErrorEvent = (event: any) => {
		// Use a different eventCategory for uncaught errors.
		const fieldsObj = { eventCategory: 'Uncaught Error' }

		// Some browsers don't have an error property, so we fake it.
		const err = event.error || {
			message: `${event.message} (${event.lineno}:${event.colno})`,
		}

		trackError(err, fieldsObj)
	}

	// Replay any stored load error events.
	for (const event of loadErrorEvents) {
		trackErrorEvent(event)
	}

	// Add a new listener to track event immediately.
	window.addEventListener('error', trackErrorEvent)
}

/**
 * Creates the trackers and sets the default transport and tracking
 * version fields. In non-production environments it also logs hits.
 */
const createTracker = (id: string) => {
	gtag('js', new Date())
	gtag('config', id)
}

export const init = (id: string) => {
	createTracker(id)
	trackErrors()
}
