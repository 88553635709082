export const pad = (
	input: string | number,
	targetLength = 0,
	padString = ' '
) =>
	`${padString.repeat(targetLength)}${input}`.slice(
		targetLength +
			String(input).length -
			Math.max(targetLength, String(input).length)
	)

export const pluralize = (no: number, input: string) =>
	no === 1 ? input : `${input}s`
