import { h } from 'preact'
import { Params } from '../../../services/params'
import { route } from '../../../services/router'
import { format } from '../../../services/time'
import { Icon } from '../../Common/Icon/Icon'
import styles from './HistoryEntry.less'

export const HistoryEntry = (props: {
	entry: Params
	remove: (path: string) => void
}) => {
	const { message, path, timestamp } = props.entry

	return (
		<li className={styles.historyEntry} key={path} onClick={() => route(path)}>
			<span className={styles.details}>
				<span className={styles.message}>{message}</span>
				<span className={styles.datetime}>{format(timestamp)}</span>
			</span>
			<button
				className={`btn btn-icon ${styles.deleteButton}`}
				title="Delete"
				onClick={() => props.remove(path)}
			>
				<Icon className="icon-delete" />
			</button>
		</li>
	)
}
