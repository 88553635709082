import * as router from 'preact-router'

export type IListener = (data: { path: string }) => void

const listeners: IListener[] = []

const notify = () => {
	listeners.forEach((listener) => listener({ path: location.pathname }))
}

export const route = (url: string, replace?: boolean) => {
	router.route(url, replace)
	notify()
}

export const onNavigation = (fn: IListener) => {
	const index = listeners.push(fn) - 1
	return () => listeners.splice(index, 1)
}

window.addEventListener('load', notify)
window.addEventListener('popstate', notify)
