import { h } from 'preact'
import * as history from '../../services/history'
import { Popup } from '../Common/Popup/Popup'
import { HistoryEntry } from './HistoryEntry/HistoryEntry'

export function History() {
	return (
		<Popup>
			{history
				.get()
				.reverse()
				.map((entry) => (
					<HistoryEntry
						key={entry.path}
						entry={entry}
						remove={history.remove}
					/>
				))}
		</Popup>
	)
}
