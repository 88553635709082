import { h } from 'preact'

export const FormGroup = (props: { children?: any; className?: string }) => {
	return (
		<div className={`group ${props.className}`}>
			<label>
				{props.children}
				<div className="bar" />
			</label>
		</div>
	)
}
