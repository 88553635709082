import { h } from 'preact'
import { toMinutes } from '../../services/time'
import { Icon } from '../Common/Icon/Icon'
import styles from './Controls.less'

export const Controls = (props: {
	message: string
	timestamp: number
	toggleShowHistory: (show?: boolean) => void
}) => {
	const messageEncoded = encodeURIComponent(props.message)

	return (
		<div className={styles.controls}>
			<div className={styles.share}>
				<div className={styles.content}>
					<a
						className={styles.shareLink}
						onClick={() => {
							try {
								navigator.share({ text: props.message })
							} catch (e) {
								// ignore
							}
						}}
					>
						<Icon className="icon-link" />
					</a>
				</div>
			</div>
			<div className={styles.actions}>
				<div className={styles.content}>
					<button
						className={`btn btn-link ${styles.link}`}
						onClick={() => props.toggleShowHistory()}
					>
						History
					</button>
					<a
						className="edit-button"
						href={`/edit/${toMinutes(props.timestamp)}/${messageEncoded}`}
					>
						Edit
					</a>
					<a className="new-button" href="/">
						New Countdown
					</a>
				</div>
			</div>
		</div>
	)
}
