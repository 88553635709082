export type Time = {
	years: number
	months: number
	weeks: number
	days: number
	hours: number
	minutes: number
	seconds: number
	totalSeconds: number
}

export const toMinutes = (timestamp: number) =>
	Math.round(timestamp / 1000 / 60)

export const toMilliseconds = (minutes: number) =>
	Math.round(minutes * 60 * 1000)

export const format = (date: Date | number) =>
	new Intl.DateTimeFormat(undefined, {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	}).format(date)

export const formatLocalIso = (timestamp: number) =>
	new Date(timestamp - timezoneOffset()).toISOString().slice(0, -1)

/** Gets the offset between local and UTC timezone in milliseconds. */
export const timezoneOffset = (date = new Date()) =>
	toMilliseconds(date.getTimezoneOffset())

export const diff = (timestamp: number) => {
	const n = new Date()
	const t = new Date(timestamp)

	const totalMilliseconds = t.valueOf() - n.valueOf()
	const totalSeconds = Math.ceil(totalMilliseconds / 1000)

	return {
		years: t.getUTCFullYear() - n.getUTCFullYear(),
		months: Math.floor(totalSeconds / 60 / 60 / 24 / 30.4368),
		weeks: Math.floor(totalSeconds / 60 / 60 / 24 / 7),
		days: Math.floor(totalSeconds / 60 / 60 / 24) % 7,
		hours: Math.floor(totalSeconds / 60 / 60) % 24,
		minutes: Math.floor(totalSeconds / 60) % 60,
		seconds: totalSeconds % 60,
		totalSeconds,
	} as Time
}
