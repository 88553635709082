import { h } from 'preact'
import { Icon } from '../../Common/Icon/Icon'
import styles from './FullscreenButton.less'

let fullscreenEnabled = 'webkitFullscreenEnabled'
let requestFullscreen = 'webkitRequestFullscreen'

if ('mozFullScreen' in document) {
	fullscreenEnabled = 'mozFullScreenEnabled'
	requestFullscreen = 'mozRequestFullScreen'
}

const isSupported = (document as any)[fullscreenEnabled]

const enterFullscreen = () =>
	(document.documentElement as any)[requestFullscreen]()

export const FullscreenButton = () => {
	if (!isSupported) {
		return null
	}

	return (
		<button
			className={styles.fullscreen}
			title="Toggle fullscreen"
			onClick={enterFullscreen}
		>
			<Icon className="icon-enlarge" />
		</button>
	)
}
