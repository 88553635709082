import { h } from 'preact'
import Router from 'preact-router'
import { useEffect, useState } from 'preact/hooks'
import * as history from '../services/history'
import { onNavigation } from '../services/router'
import { ConfigPage } from './ConfigPage/ConfigPage'
import { CountdownPage } from './CountdownPage/CountdownPage'
import { History } from './History/History'

export function App() {
	const [showHistory, setShowHistory] = useState(false)

	useEffect(() => {
		const removeListener = onNavigation(({ path }) => {
			history.add(path)
			setShowHistory(false)
		})

		document.onkeydown = (event) => {
			if ((event.target as any).tagName === 'INPUT') {
				return
			}

			if (event.key === 'Escape') {
				setShowHistory(false)
			} else if (['H', '?'].includes(event.key)) {
				setShowHistory(!showHistory)
			}
		}

		return () => {
			removeListener()
			document.onkeydown = () => undefined
		}
	})

	return (
		<div className="root">
			<Router>
				<ConfigPage path="/" />
				<ConfigPage path="/edit/:timestamp/:message" />
				<CountdownPage
					path="/:timestamp/:message?"
					toggleShowHistory={() => setShowHistory(!showHistory)}
				/>
			</Router>
			{showHistory && <History />}
		</div>
	)
}
